<template>
  <div>
    <b-card title="Type de quotation">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Nom">
            <b-form-input
              v-model="$v.title.$model"
              :formatter="formatter"
              :class="{ 'is-invalid': $v.title.$error }"
            />
            <div
              v-if="$v.title.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.title.required"
              >Le nom est requis.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <div class="text-right">
            <b-button
              variant="light"
              :disabled="process"
              @click="reset"
            >
              Rafraichir
            </b-button>
            <b-button
              variant="success"
              class="ml-2"
              type="submit"
              :disabled="process"
              @click=" addQuotationType"
            >
              <b-spinner
                v-if="process"
                small
              />
              <span v-show="!process">Ajouter</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      title: null,
    }
  },
  validations: {
    title: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      success: 'quotationTypeSuccess',
      error: 'quotationTypeError',
      process: 'quotationTypeProcess',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        switch (val.valid) {
          case 1:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Type de cotation',
                text: val.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            break

          default:
            this.reset()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Type de cotation',
                text: val.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            break
        }
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Type de cotation',
            text: 'Une erreur est survenue.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['newQuotationType']),
    formatter(value) {
      return value.toUpperCase()
    },
    reset() {
      this.title = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    addQuotationType() {
      this.$v.title.$touch()
      if (this.$v.title.$anyError) {
        return
      }
      this.newQuotationType({
        title: this.title,
      })
    },
  },
}
</script>
